import { fetch } from './fetch'
import { api } from '@/config'

const { API_APP_URL, TEXTS_URL, API_DOMAIN } = api

const getTextsBySlug = ({ params: { slug, ...rest } }) =>
  fetch({
    method: 'get',
    url: `${API_APP_URL}${TEXTS_URL}/${slug}`,
    params: { ...rest }
  })

export const textsAPI = {
  getTextsBySlug
}
