import { useTranslation } from 'next-i18next'
import { FC } from 'react'

import Button from '@/components/Button'
import Icon from '@/components/Icon'
import Link from '@/components/Link'

import useMediaQuery from '@/hooks/useMediaQuery'

import { useMenu } from '@/bus/menu'
import { routes } from '@/config'

export const RegisterHeader: FC = () => {
  const isMiddleScreen = useMediaQuery('(min-width: 640px)')
  const isSmallScreen = useMediaQuery('(min-width: 375px)')

  const { t } = useTranslation()
  const { setData } = useMenu()

  return (
    <>
      {isMiddleScreen && (
        <Button
          title={t('forms:buttons.haveAnAccount') as string}
          as={'span'}
          variant={'link'}
          onClick={() => setData({ shown: false })}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: t('forms:buttons.haveAnAccount')
            }}
          />
        </Button>
      )}
      {isSmallScreen && (
        <Button
          title={t('forms:buttons.signin') as string}
          as={Link}
          onClick={() => setData({ shown: false })}
          href={routes.LOGIN}
          before={<Icon name={'user/24'} />}
        >
          {t('forms:buttons.signin')}
        </Button>
      )}
      <Button
        title={t('forms:buttons.goHome') as string}
        as={Link}
        href={routes.HOME}
        variant={'light'}
        size={'icon'}
      >
        <Icon name={'cross/32'} color={'#1E2F4B'} />
      </Button>
    </>
  )
}
