import { useRouter } from 'next/router'
import { FC, useEffect, useMemo } from 'react'

import { setCookie } from '@/utils/cookie'

const ReferralProgram: FC = () => {
  const router = useRouter()
  const programs = useMemo(
    () => [
      'ref',
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_term',
      'utm_content'
    ],
    []
  )

  const foundParameters = useMemo(() => {
    const getParams = Object.keys(router.query)

    if (!getParams.length) {
      return
    }

    return getParams.filter(param => programs.includes(param))
  }, [programs, router.query])

  useEffect(() => {
    const referrer = document.referrer

    const props = {
      path: '/',
      expires: 31
    }

    if (referrer.length && !/^https:\/\/getuniq.me/.test(referrer)) {
      setCookie('referrer', referrer, props)
    }

    if (!foundParameters) {
      return
    }

    foundParameters.forEach(param => {
      setCookie(param, router.query[param], props)
    })
  }, [foundParameters, router.query])

  return <div />
}

export default ReferralProgram
