import clsx from 'clsx'
import { FC } from 'react'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Content.module.scss'

export const Content: FC<DefaultComponentProps> = ({
  className = '',
  children = null
}) => <section className={clsx(styles.content, className)}>{children}</section>
