import { MODAL_HIDE, MODAL_SHOW } from './types'

const initialState = {
  modalType: null,
  modalProps: {}
}

export function modalsReducer(state = initialState, action) {
  switch (action.type) {
    case MODAL_SHOW: {
      const {
        payload: { modalType, modalProps }
      } = action
      return {
        ...state,
        modalType,
        modalProps
      }
    }
    case MODAL_HIDE:
      return {
        ...initialState
      }
    default:
      return state
  }
}
