import { useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'
import { FC, useEffect, useMemo, useState } from 'react'

import ItemProps from '@/components/Menu/ItemProps'

import useMediaQuery from '@/hooks/useMediaQuery'

import DefaultComponentProps from '@/types/DefaultComponentProps'

const Desktop = dynamic(
  () => import('./Desktop').then(({ Desktop }) => Desktop),
  {
    ssr: false
  }
)

const Mobile = dynamic(() => import('./Mobile').then(({ Mobile }) => Mobile), {
  ssr: false
})

const Menu: FC<DefaultComponentProps> = ({ className = '' }) => {
  const isDesktopScreen = useMediaQuery('(min-width: 960px)')
  const [isReady, setReady] = useState(false)
  const { t } = useTranslation()

  const ITEMS: ItemProps[] = t('menu:list', { returnObjects: true })

  const desktopItems = useMemo(
    () => ITEMS.filter(({ desktop = false }) => desktop),
    [ITEMS]
  )

  useEffect(() => {
    setReady(true)
  }, [])

  if (!isReady) {
    return null
  }

  return isDesktopScreen ? (
    <Desktop className={className} items={desktopItems} />
  ) : (
    <Mobile className={className} items={ITEMS} />
  )
}

export default Menu
