import clsx from 'clsx'
import React, { FC, PropsWithChildren } from 'react'

import Button from '@/components/Button'
import Icon from '@/components/Icon'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './alert.module.scss'

interface AlertProps {
  severity: 'error' | 'warning' | 'info' | 'success'
  handleClose: Function
}
const Alert: FC<PropsWithChildren & DefaultComponentProps & AlertProps> = ({
  className,
  children,
  severity = 'info',
  handleClose = () => {},
  ...rest
}) => (
  <div
    {...rest}
    className={clsx(
      className,
      styles['alert'],
      styles[`alert_severity-${severity}`]
    )}
  >
    <div className={styles['alert__text']}>{children}</div>

    <Button
      size={'icon'}
      variant={'unstyled'}
      onClick={() => handleClose()}
      className={styles['alert__close-btn']}
    >
      <Icon name={'cross/24'} color={'#fff'} />
    </Button>
  </div>
)

export default Alert
