import { useDispatch, useSelector } from 'react-redux'

import { setSettingsAction } from '@/bus/ui/actions'
import { IRootState } from '@/store/reducer'

export const useUi = () => {
  const dispatch = useDispatch()

  const { settings } = useSelector(({ ui }: IRootState) => ui)

  const setSettings = (props: any) => {
    const action = setSettingsAction(props)
    dispatch(action)
  }

  return {
    settings,
    setSettings
  }
}
