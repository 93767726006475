import { types } from './types'

const initialState = {
  loading: false,
  loaded: false,
  failed: false,
  data: {}
}

export function textsReducer(state = initialState, action: any) {
  switch (action.type) {
    case types['TEXTS/LOADING']: {
      return {
        ...state,
        ...action.payload
      }
    }
    case types['TEXTS/SET_DATA']: {
      return {
        ...state,
        ...action.payload
      }
    }
    case types['TEXTS/ERRORS']: {
      return {
        ...state,
        ...action.payload
      }
    }
    default:
      return state
  }
}
