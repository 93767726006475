import { FC, ReactNode } from 'react'

/*import LangSwitcher from '@/components/LangSwitcher'
import Logo from '@/components/Logo'
import LogoIcon from '@/components/LogoIcon'*/
import styles from './DoublePanel.module.scss'

// import Header from '@/layouts/Header'

interface DoublePanelProps {
  children: ReactNode
  // headerAfter?: ReactNode
  side?: ReactNode
}
export const DoublePanel: FC<DoublePanelProps> = ({
  children = null,
  // headerAfter = null,
  side = null
}) => {
  return (
    <main className={styles['double-panel']}>
      <section className={styles['double-panel__content']}>
        <div className={styles['double-panel__content-inner']}>{children}</div>
      </section>
      <aside className={styles['double-panel__side']}>{side}</aside>
    </main>
  )
}
