import { takeLatest } from 'redux-saga/effects'

import { types } from '../types'

import { getTextsWorker } from './worker'

export function* watchTexts() {
  // @ts-ignore
  yield takeLatest(types['TEXTS/GET_DATA'], getTextsWorker)
}
