module.exports = {
  i18n: {
    defaultLocale: 'ru',
    locales: ['ru', 'en'],
    localeDetection: false
  },
  react: {
    useSuspense: true
  }
}
