import { FC } from 'react'

import useDynamicSVGImport from '@/hooks/useDynamicSVGImport'

interface IconProps {
  className?: string
  name: string
  color?: string
  width?: number | string
  height?: number | string
  style?: object
  onCompleted?: Function
  onError?: Function
}

const Icon: FC<IconProps> = ({ name, onCompleted, onError, ...rest }) => {
  const { error, loading, SvgIcon } = useDynamicSVGImport(name, {
    onCompleted: () => {},
    onError: () => {}
  })
  if (error) {
    return null
  }
  if (loading) {
    return null
  }
  if (SvgIcon) {
    // @ts-ignore
    return <SvgIcon {...rest} />
  }
  return null
}
export default Icon
