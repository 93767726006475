import clsx from 'clsx'
import { FC } from 'react'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './FooterCopyright.module.scss'

const FooterCopyright: FC<DefaultComponentProps> = ({ className = '' }) => (
  <div className={clsx(styles['footer-copyright'], className)}>
    © {new Date().getFullYear()}
  </div>
)
export default FooterCopyright
