import { useTranslation } from 'next-i18next'
import { FC } from 'react'

import Button from '@/components/Button'
import Icon from '@/components/Icon'
import Link from '@/components/Link'

import useMediaQuery from '@/hooks/useMediaQuery'

import { useUser } from '@/bus/user'
import { routes } from '@/config'
import styles from '@/layouts/Main/Main.module.scss'

export const DefaultHeader: FC = () => {
  const { isAuthorized } = useUser()
  const { t } = useTranslation()
  const isSmallScreen = useMediaQuery('(min-width: 375px)')
  const isMiddleScreen = useMediaQuery('(min-width: 640px)')

  return (
    <>
      {isAuthorized ? (
        <Button
          href={{
            pathname: routes.SERVICES,
            external: true
          }}
          as={Link}
          className={styles['section__button-link']}
          before={<Icon name="arrows/right/24" />}
        >
          {t('forms:buttons.services')}
        </Button>
      ) : (
        <>
          {isMiddleScreen && (
            <Button
              as={Link}
              href={routes.REGISTER}
              variant="light"
              className={styles['main__button']}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: t('forms:buttons.signup')
                }}
              />
            </Button>
          )}
          {isSmallScreen && (
            <Button
              className={styles['main__button']}
              as={Link}
              href={routes.LOGIN}
              before={<Icon name={'user/24'} />}
            >
              {t('forms:buttons.signin')}
            </Button>
          )}
        </>
      )}
    </>
  )
}
