import { FC } from 'react'
import { createPortal } from 'react-dom'

import Notification from '@/components/Notifications/Notification'

import NotificationProps from '@/types/Notification'

import styles from './notifications.module.scss'
import { useNotifications } from '@/bus/notifications'

const Notifications: FC = () => {
  const { data: notifications } = useNotifications()

  if (!notifications.length) {
    return null
  }

  return createPortal(
    <div className={styles.notifications}>
      {notifications.map((notification: NotificationProps) => (
        <Notification key={notification.UUID} {...notification} />
      ))}
    </div>,
    document.body
  )
}

export default Notifications
