import { useTranslation } from 'next-i18next'

import Button from '@/components/Button'
import Icon from '@/components/Icon'
import Link from '@/components/Link'

import useMediaQuery from '@/hooks/useMediaQuery'

import { useMenu } from '@/bus/menu'
import { routes } from '@/config'

export const LoginHeader = () => {
  const isMiddleScreen = useMediaQuery('(min-width: 640px)')

  const { t } = useTranslation()
  const { setData } = useMenu()
  return (
    <>
      {isMiddleScreen && (
        <Button
          title={t('forms:buttons.dontHaveAnAccount') as string}
          as={'span'}
          variant={'link'}
          onClick={() => setData({ shown: false })}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: t('forms:buttons.dontHaveAnAccount')
            }}
          />
        </Button>
      )}
      {isMiddleScreen && (
        <Button
          title={t('forms:buttons.createAccount') as string}
          onClick={() => setData({ shown: false })}
          as={Link}
          href={routes.REGISTER}
          before={<Icon name={'user/24'} />}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: t('forms:buttons.createAccount')
            }}
          />
        </Button>
      )}

      <Button
        title={t('forms:buttons.createAccount') as string}
        as={Link}
        href={routes.HOME}
        variant={'light'}
        size={'icon'}
      >
        <Icon name={'cross/32'} color={'#1E2F4B'} />
      </Button>
    </>
  )
}
