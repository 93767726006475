import { types } from './types'

interface DataProps {
  id?: number
  name?: string
  balance?: object
}

interface InitialStateProps {
  loading: boolean
  loaded: boolean
  failed: boolean
  data: DataProps
  is2FA: boolean
}

const initialState: InitialStateProps = {
  loading: false,
  loaded: false,
  failed: false,
  data: {},
  is2FA: false
}

export function userReducer(state = initialState, action: any) {
  switch (action.type) {
    case types['USER/ERRORS']:
    case types['USER/SET_DATA']: {
      return {
        ...state,
        ...action.payload
      }
    }

    case types['USER/CANCEL_2FA']: {
      return {
        ...state,
        is2FA: false
      }
    }

    default:
      return state
  }
}
