import axios from 'axios'

import { api } from '@/config'

// Import axios library for HTTP requests

const { API_APP_URL, ACCESS_TOKEN_URL, SECURITY_SESSION_URL } = api

// API_URLs
const REFRESH_TOKEN = `${API_APP_URL}${ACCESS_TOKEN_URL}` // Refresh token API endpoint
const START_SESSION = `${API_APP_URL}${SECURITY_SESSION_URL}`

// Function to fetch Auth Token from Local Storage
export function getAuthToken(key = 'access_token') {
  // Fetch token from local storage
  return localStorage.getItem(key)
}

// Function to refresh Access Token
function doGetRefreshToken() {
  let headers = { 'Content-Type': 'application/json' } // Set headers
  let data = { refresh_token: getAuthToken('refresh_token') } // Set request body with refresh token

  // Return promise object for async operations
  return new Promise((resolve, reject) => {
    // Make POST request to refresh token API endpoint with headers and data
    return RestAxiosService({
      url: REFRESH_TOKEN,
      method: 'POST',
      data,
      headers
    }).then(
      res => {
        // On successful response, store new access token in local storage
        if (res.status === 200) {
          const {
            data: {
              token: { access_token = null, refresh_token = null } = {}
            } = {}
          } = res.data
          if (access_token && refresh_token) {
            localStorage.setItem('access_token', access_token)
            localStorage.setItem('refresh_token', refresh_token)
            resolve({ status: res.status, data: res.data })
          }
        } else if (res.status === 401) {
          // On unauthorized response, clear local storage
          localStorage.removeItem('access_token')
          localStorage.removeItem('refresh_token')
          reject({ status: res.status, data: res.data })
          // window.location.href = '/'
        } else return // If status code is neither 200 nor 401, exit function
      },
      err => {
        reject(err) // On request failure, reject promise
      }
    )
  })
}

// Function to perform HTTP requests
export function RestAxiosService({ url, headers, ...rest }) {
  let options = {
    url,
    headers,
    ...rest
  }

  const { Authorization: isAuthorizationHeader = null } = headers

  // Return promise object for async operations
  return new Promise((resolve, reject) => {
    // Perform HTTP request with options
    return axios(options)
      .then(res => {
        // If status code indicates success, resolve promise with response data
        if (res.status >= 200 && res.status < 300) {
          resolve({ status: res.status, data: res.data })
        } else {
          // Otherwise, reject promise with response data
          reject({ status: res.status, data: res.data })
        }
      })
      .catch(e => {
        // If there's a response object and URL doesn't include REFRESH_TOKEN url
        if (typeof e === 'object' && typeof e.response === 'object') {
          if (isAuthorizationHeader) {
            // If status code is 401 (Unauthorized), attempt to refresh token
            if (e.response.status === 401) {
              if (url.indexOf(START_SESSION) > -1) {
                localStorage.removeItem('access_token')
                localStorage.removeItem('refresh_token')
                return
              }
              doGetRefreshToken().then(
                res => {
                  // If status code indicates success, retry original request with new access token
                  if (res.status >= 200 && res.status < 299) {
                    let updatedHeaders = {
                      ...headers,
                      Authorization: 'Bearer ' + getAuthToken('access_token')
                    }

                    RestAxiosService({
                      url,
                      headers: updatedHeaders,
                      ...rest
                    }).then(
                      retryResponse => {
                        resolve(retryResponse)
                      },
                      retryError => {
                        reject(retryError)
                      }
                    )
                  }
                  // If status code is 401, it means that the refresh token is also expired.
                  else if (res.status === 401) {
                    // Clear local storage
                    localStorage.removeItem('access_token')
                    localStorage.removeItem('refresh_token')
                  } else return
                },
                err => {
                  if (err.status === 401) {
                    // Clear local storage
                    localStorage.removeItem('access_token')
                    localStorage.removeItem('refresh_token')
                  }
                  reject({
                    status: err.status,
                    data: err.data
                  })
                }
              )
            } else {
              // If URL includes REFRESH_TOKEN url, reject promise with response data
              reject({ status: e.response.status, data: e.response.data })
            }
          } else {
            reject({ status: e.response.status, data: e.response.data })
          }
        } else {
          // If unexpected error occurred, reject promise with custom status code and error message
          reject({ status: 532, data: 'Internet Connection Failure' })
        }
      })
  })
}
