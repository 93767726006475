import { takeLatest } from 'redux-saga/effects'

import { types } from '../types'

import { apiErrorWorker, fetchDataWorker } from './worker'

export function* watchApiError() {
  yield takeLatest(types['API/ERROR'], apiErrorWorker)
  yield takeLatest(types['API/FETCH_DATA'], fetchDataWorker)
}
