import { types } from './types'

const initialState = {
  shown: false,
  interSection: null
}

export function menuReducer(state = initialState, action) {
  switch (action.type) {
    case types['MENU/SET_DATA']:
      return {
        ...state,
        ...action.payload
      }

    default:
      return state
  }
}
