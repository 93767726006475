import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setRouterAction } from '@/bus/router/actions'
import { IRootState } from '@/store/reducer'

export const useRouter = () => {
  const dispatch = useDispatch()
  const router = useSelector(({ router }: IRootState) => router)

  const setRouter = useCallback(
    (props: object) => {
      const action = setRouterAction(props)
      dispatch(action)
    },
    [dispatch]
  )

  return {
    router,
    setRouter
  }
}
