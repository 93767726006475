import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { FC, useCallback, useMemo } from 'react'

import Icon from '@/components/Icon'
import Link from '@/components/Link'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Logo.module.scss'
import { useRouter } from '@/bus/router'
import { routes } from '@/config'

interface LogoProps extends DefaultComponentProps {
  light?: boolean
}

const Logo: FC<LogoProps> = ({ className = '', light = false }) => {
  // @ts-ignore
  const { location } = useRouter()

  const { t } = useTranslation()

  const scrollToTop = useCallback((e: { preventDefault: () => void }) => {
    e.preventDefault()
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const props = useMemo(
    () => (location?.pathname === routes.HOME ? { onClick: scrollToTop } : {}),
    [location, scrollToTop]
  )

  return (
    <Link
      {...props}
      className={clsx(styles['logo'], className, {
        [styles['logo_light']]: light
      })}
      href={routes.HOME}
      title={t('forms:buttons.goHome') as string}
    >
      <Icon className={styles['logo__icon']} name="getuniq/53" />
    </Link>
  )
}

export default Logo
