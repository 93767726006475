import { useTranslation } from 'next-i18next'
import { FC, useEffect, useMemo, useState } from 'react'
import { Link as ReactLink } from 'react-router-dom'

import { LinkProp } from '@/components/Link/LinkProp'

const PROTOCOL = new RegExp('^(http|https)://', 'i')

const Link: FC<LinkProp> = ({
  children = null,
  href = '/',
  locale = null,
  ...rest
}) => {
  // все эти манипуляции нужны для того, чтобы можно было передавать разные виды ссылок
  // от самого простого string с или без указания домена
  // до сложного объекта
  // {
  //   pathname: string
  //   query?: { [key: string]: string }
  // }
  // Стандартный компонент Link от Next не подошёл из-за того, что он делает лишние запросы в сбилденных файлах при клике на ссылку, которые порождают на проде ошибки

  const [isServer, setIsServer] = useState(true)

  useEffect(() => {
    setIsServer(false)
  }, [])

  const {
    i18n: { language }
  } = useTranslation()

  // текущая языковая версия
  const currentLocale = useMemo(() => locale || language, [locale, language])

  const {
    pathname = '',
    query = {},
    external = false
  } = typeof href === 'object' && href !== null ? href : { pathname: href }

  // ссылка и хжш тэг
  const [path = '', hash = ''] = useMemo(() => pathname.split('#'), [pathname])

  // если передана ссылка с доменом
  const isPathExternal = useMemo(
    () => PROTOCOL.test(path) || path.indexOf(':lang') > -1 || external,
    [path, external]
  )

  // формирование гет параметров для ссылки
  const queryString = useMemo(
    () =>
      Object.keys(query).length
        ? '?' + new URLSearchParams(query).toString()
        : '',
    [query]
  )

  // формирование хэш тега для ссылки
  const hashString = useMemo(() => (hash.length ? `#${hash}` : ''), [hash])

  const url = useMemo(() => {
    // если переданная ссылка с доменом, то отдаём её без добавления языковой версии
    if (isPathExternal) {
      return path.replace(':lang', currentLocale) + queryString + hashString
    }

    const url = []

    // если русская языковая версии или ссылка на кабинет, то добавляем языковую версию
    if (currentLocale !== 'ru') {
      url.push('/', currentLocale)
    }

    // если ссылка не на главную или на главную, но русскую версию добавляем ссылку
    if (path !== '/' || (path === '/' && currentLocale === 'ru')) {
      url.push(path)
    }

    // добавляем хэш
    url.push(hashString)

    // добавляем гет парметры
    url.push(queryString)

    // возвращаем сгенерированную ссылку
    return url.join('')
  }, [isPathExternal, currentLocale, path, hashString, queryString])

  if (isServer || isPathExternal) {
    return (
      // @ts-ignore
      <a {...rest} href={url}>
        {children}
      </a>
    )
  }

  return (
    // @ts-ignore
    <ReactLink {...rest} to={url}>
      {children}
    </ReactLink>
  )
}
export default Link
