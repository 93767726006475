import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { FC, useMemo } from 'react'

import Link from '@/components/Link'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import i18nextConfig from '../../../next-i18next.config'

import styles from './LangSwitcher.module.scss'
import { useRouter } from '@/bus/router'
import { routes } from '@/config'

const localeRegexp = new RegExp('^/([a-z]{2})(/.*)?$')

const LangSwitcher: FC<DefaultComponentProps> = ({ className = '' }) => {
  const {
    i18n: { language }
  } = useTranslation()
  const { router } = useRouter()

  const handleClick = (e: React.BaseSyntheticEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    window.location.href = e.target.getAttribute('href')
  }

  const url = useMemo(() => router.location?.pathname ?? routes.HOME, [router])

  return (
    <nav className={clsx(styles['lang-switcher'], className)}>
      <ul className={styles['lang-switcher__list']}>
        {i18nextConfig.i18n.locales.map(locale => (
          <li key={locale} className={styles['lang-switcher__item']}>
            <Link
              onClick={handleClick}
              className={clsx('link-alike', styles['lang-switcher__link'], {
                [styles['lang-switcher__link_active']]: language === locale
              })}
              href={url}
              locale={locale}
              title={locale}
            >
              {locale.toUpperCase()}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default LangSwitcher
