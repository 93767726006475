import {
  NOTIFICATION_ADD,
  NOTIFICATION_REMOVE,
  NOTIFICATION_REMOVE_AFTER_DELAY,
  NOTIFICATION_SHOW
} from './types'

export const removeNotificationAfterDelayAction = (props: any) => {
  return {
    type: NOTIFICATION_REMOVE_AFTER_DELAY,
    payload: props
  }
}

export const removeNotificationAction = (props: any) => {
  return {
    type: NOTIFICATION_REMOVE,
    payload: props
  }
}

export const addNotificationAction = (props: any) => {
  return {
    type: NOTIFICATION_ADD,
    payload: props
  }
}

export const showNotificationAction = (props: any) => {
  return {
    type: NOTIFICATION_SHOW,
    payload: props
  }
}
