import { useDispatch, useSelector } from 'react-redux'

import { setData as actionSetData } from './actions'

export const useMenu = () => {
  const dispatch = useDispatch()

  const { shown, interSection } = useSelector(({ menu }) => menu)

  const setData = props => {
    const action = actionSetData(props)
    dispatch(action)
  }

  return {
    shown,
    setData,
    interSection
  }
}
