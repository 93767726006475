import clsx from 'clsx'
import { ElementType, FC, ReactNode } from 'react'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Heading.module.scss'

interface HeadingProps extends DefaultComponentProps {
  level?: number
  children: ReactNode
  id?: string
  onClick?: Function
}
const Heading: FC<HeadingProps> = ({
  level = 1,
  className = '',
  children,
  ...rest
}) => {
  const Tag = `h${level}` as ElementType

  if (!children) {
    return null
  }

  return (
    <Tag
      className={clsx(
        styles['heading'],
        styles[`heading_level-${level}`],
        className
      )}
      {...rest}
    >
      {typeof children === 'string' ? (
        <span dangerouslySetInnerHTML={{ __html: children }} />
      ) : (
        children
      )}
    </Tag>
  )
}
export default Heading
