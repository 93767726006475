import {
  NOTIFICATION_ADD,
  NOTIFICATION_REMOVE
} from '@/bus/notifications/types'

interface DataProps {
  UUID: string
  type: string
}

interface InitialStateProps {
  data: DataProps[]
}

const initialState: InitialStateProps = {
  data: []
}

export function notificationsReducer(state = initialState, action: any) {
  switch (action.type) {
    case NOTIFICATION_ADD: {
      return {
        ...state,
        data: [...state.data, action.payload]
      }
    }

    case NOTIFICATION_REMOVE: {
      return {
        ...state,
        data: state.data.filter(({ UUID }) => UUID != action.payload.UUID),
        ...action.payload
      }
    }
    default:
      return state
  }
}
