import { types } from './types'

const initialState = {
  error_500: false
}

export function apiErrorReducer(state = initialState, action) {
  switch (action.type) {
    case types['API/ERROR_500']:
      return {
        ...state,
        error_500: action.payload
      }

    default:
      return state
  }
}
