import { useDispatch, useSelector } from 'react-redux'

import { modalHideAction, modalShowAction } from './actions'

export const useModals = () => {
  const { modalType, modalProps } = useSelector(({ modals }) => modals)
  const dispatch = useDispatch()

  const modalShow = ({ type, props }) => {
    dispatch(modalShowAction({ modalType: type, modalProps: props }))
  }

  const modalHide = () => {
    dispatch(modalHideAction())
  }

  return { modalShow, modalHide, modalType, modalProps }
}
