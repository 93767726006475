import { types } from './types'

interface InitialStateProps {
  location: Object | null
}

const initialState: InitialStateProps = {
  location: null
}

export function routerReducer(state = initialState, action: any) {
  switch (action.type) {
    case types['ROUTER/SET_ROUTER']: {
      return {
        ...state,
        ...action.payload
      }
    }

    default:
      return state
  }
}
