import { types } from './types'

export const apiErrorAction = props => ({
  type: types['API/ERROR'],
  payload: props
})

export const setLoadingAction = ({ type, payload }) => ({
  type: type + '/' + types['LOADING'],
  payload
})
export const setErrorAction = ({ type, payload }) => ({
  type: type + '/' + types['ERRORS'],
  payload
})
