import clsx from 'clsx'
import dynamic from 'next/dynamic'
import { FC, ReactNode, useEffect, useState } from 'react'
import LazyLoad from 'react-lazy-load'

import LangSwitcher from '@/components/LangSwitcher'
import Logo from '@/components/Logo'
import LogoIcon from '@/components/LogoIcon'
import Menu from '@/components/Menu'

import styles from './Main.module.scss'
import { useUi } from '@/bus/ui'
import Header from '@/layouts/Header'
import { DefaultHeader } from '@/layouts/Header/DefaultHeader'
import { LoginHeader } from '@/layouts/Header/LoginHeader'
import { RecoveryHeader } from '@/layouts/Header/RecoveryHeader'
import { RegisterHeader } from '@/layouts/Header/RegisterHeader'

const Footer = dynamic(
  () => import('@/layouts/Footer').then(({ Footer }) => Footer),
  {
    ssr: false
  }
)

interface LayoutProps {
  children: ReactNode
  footerAnimation?: boolean
}

export const Main: FC<LayoutProps> = ({ children, footerAnimation = true }) => {
  const [animationStart, setAnimationStart] = useState(false)

  const { settings } = useUi()

  useEffect(() => {
    let timeout: any

    timeout = setTimeout(() => {
      setAnimationStart(true)
    }, 300)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const HeaderAfter = () => {
    if (settings?.register) {
      return <RegisterHeader />
    }

    if (settings?.login) {
      return <LoginHeader />
    }

    if (settings?.recovery) {
      return <RecoveryHeader />
    }

    if (settings?.fourOhFour) {
      return <></>
    }

    return <DefaultHeader />
  }

  return (
    <main
      className={clsx(styles['main'], {
        ['animation-start']: animationStart
      })}
    >
      <LogoIcon className={styles['main__logo-icon']} />
      <Header
        className={styles['main__header']}
        before={
          <>
            <Logo />
            <LangSwitcher />
          </>
        }
        after={<HeaderAfter />}
      >
        <Menu
          className={clsx(styles['main__menu'], {
            [styles['main__menu_visible']]: settings.menu
          })}
        />
      </Header>
      <section className={styles['main__content']}>{children}</section>
      {settings.footer && (
        <LazyLoad className={styles['main__lazy-footer']}>
          <Footer
            className={styles['main__footer']}
            animation={footerAnimation}
          />
        </LazyLoad>
      )}
    </main>
  )
}
