import { FC, useEffect } from 'react'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import { useUser } from '@/bus/user'

export const Root: FC<DefaultComponentProps> = ({ children }) => {
  const { getUserInfo } = useUser()

  useEffect(() => {
    getUserInfo()
    // eslint-disable-next-line
  }, [])

  return <>{children}</>
}
