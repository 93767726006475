export const getCookie = (name: string) => {
  var matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
        '=([^;]*)'
    )
  )
  return matches ? decodeURIComponent(matches[1]) : undefined
}

export const setCookie = (
  name: string,
  value: any,
  props: { [key: string]: any }
) => {
  props = props || {}

  var exp = props.expires // days

  if (typeof exp === 'number' && exp) {
    var d = new Date()

    d.setTime(d.getTime() + exp * 86400 * 1000)

    exp = props.expires = d
  }

  if (exp && exp.toUTCString) {
    props.expires = exp.toUTCString()
  }

  value = encodeURIComponent(value)

  var updatedCookie = name + '=' + value

  for (var propName in props) {
    updatedCookie += '; ' + propName

    var propValue = props[propName]

    if (propValue !== true) {
      updatedCookie += '=' + propValue
    }
  }

  document.cookie = updatedCookie
}

export const deleteCookie = (name: string) => {
  // @ts-ignore
  setCookie(name, null, { expires: -1 })
}
