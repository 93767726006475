import { useDispatch, useSelector } from 'react-redux'

import {
  removeNotificationAction,
  removeNotificationAfterDelayAction
} from '@/bus/notifications'
import { IRootState } from '@/store/reducer'

export const useNotifications = () => {
  const dispatch = useDispatch()
  const { data = [] } = useSelector(
    ({ notifications }: IRootState) => notifications
  )

  const removeAfterDelay = (props: object) => {
    const action = removeNotificationAfterDelayAction(props)
    dispatch(action)
  }

  const remove = (props: object) => {
    const action = removeNotificationAction(props)
    dispatch(action)
  }

  return {
    data,
    removeAfterDelay,
    remove
  }
}
