import { combineReducers } from 'redux'

import { menuReducer } from '@/bus/menu'
import { modalsReducer } from '@/bus/modals'
import { notificationsReducer } from '@/bus/notifications'
import { routerReducer } from '@/bus/router'
import { textsReducer } from '@/bus/texts'
import { uiReducer } from '@/bus/ui'
import { userReducer } from '@/bus/user'

const rootReducer = combineReducers({
  menu: menuReducer,
  modals: modalsReducer,
  ui: uiReducer,
  user: userReducer,
  texts: textsReducer,
  notifications: notificationsReducer,
  router: routerReducer
})

export default rootReducer

export type IRootState = ReturnType<typeof rootReducer>
