import clsx from 'clsx'
import { FC } from 'react'

import Icon from '@/components/Icon'

import useMediaQuery from '@/hooks/useMediaQuery'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './FooterLogo.module.scss'

const FooterLogo: FC<DefaultComponentProps> = ({ className = '' }) => {
  const isBigScreen = useMediaQuery('(min-width: 1366px)')
  const isMiddleScreen = useMediaQuery('(min-width: 1024px)')

  return (
    <div className={clsx(styles['footer-logo'], className)}>
      <Icon
        name={`footer-logo/${
          isBigScreen ? '157x267' : isMiddleScreen ? '107x181' : '100x170'
        }`}
      />
    </div>
  )
}
export default FooterLogo
