import { put } from 'redux-saga/effects'
import { v4 as uuidv4 } from 'uuid'

import {
  addNotificationAction,
  removeNotificationAction
} from '@/bus/notifications'

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

export function* addNotification({ payload }: any) {
  try {
    const notificationUUID = uuidv4()

    yield put(addNotificationAction({ ...payload, UUID: notificationUUID }))
  } catch (e) {
    console.log(e)
  }
}

export function* removeNotificationAfterDelay({ payload }: any) {
  yield delay(payload.delay || 250)
  yield put(removeNotificationAction({ UUID: payload.UUID }))
}
