import { Context, createWrapper } from 'next-redux-wrapper'
import { Store, applyMiddleware, createStore } from 'redux'
import createSagaMiddleware, { SagaMiddleware, Task } from 'redux-saga'

import rootReducer, { IRootState } from './reducer'
import rootSaga from './saga'

export interface SagaStore extends Store {
  sagaTask?: Task
}

const bindMiddleware = (middleware: SagaMiddleware<object>[]) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension')
    return composeWithDevTools(applyMiddleware(...middleware))
  }
  return applyMiddleware(...middleware)
}

export const makeStore = (context: Context) => {
  const sagaMiddleware = createSagaMiddleware()
  const store = createStore(rootReducer, bindMiddleware([sagaMiddleware]))

  ;(store as SagaStore).sagaTask = sagaMiddleware.run(rootSaga)

  return store
}

export const wrapper = createWrapper<Store<IRootState>>(makeStore, {
  debug: false
})
