import { DefaultSeoProps } from 'next-seo';

const config: DefaultSeoProps =  {
  additionalMetaTags: [
    {
      httpEquiv: 'x-ua-compatible',
      content: 'IE=edge; chrome=1'
    },
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1'
    },
    {
      name: 'apple-mobile-web-app-title',
      content: 'getUNIQ'
    },
    {
      name: 'application-name',
      content: 'getUNIQ'
    },
    {
      name: 'msapplication-TileColor',
      content: '#ffffff'
    },
    {
      name: 'msapplication-TileImage',
      content: '/favicon/mstile-144x144.png'
    },

    {
      name: 'theme-color',
      content: '#ffffff'
    }
  ],
  additionalLinkTags: [
    {
      rel: 'icon',
      href: '/favicon/favicon.ico'
    },
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/favicon/apple-touch-icon.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicon/favicon-32x32.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '192x192',
      href: '/favicon/android-chrome-192x192.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicon/favicon-16x16.png'
    },

    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicon/favicon-16x16.png'
    },
    {
      rel: 'mask-icon',
      href: '/favicon/safari-pinned-tab.svg',
      color: '#5bbad5'
    },

    {
      rel: 'manifest',
      href: '/site.webmanifest'
    }
  ]
}

export default config