import { useEffect, useState } from 'react'

export default function useMediaQuery(mediaQueryString: string) {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQueryString)

    const listener = () => setMatches(mediaQueryList.matches)
    mediaQueryList.addListener(listener)

    // Call the listener() function immediately to set the local
    // state as soon as possible.
    listener()

    return () => {
      mediaQueryList.removeListener(listener)
    }
  }, [mediaQueryString])

  return matches
}
