import { all, call, put } from 'redux-saga/effects'

import { fetchDataWorker } from '../../apiRequest/saga/worker'
import '../../user'
import { types } from '../types'

import { userAPI } from '@/api/userAPI'
import { showNotificationAction } from '@/bus/notifications'
import { cancel2FAAction, setUserDataAction } from '@/bus/user'

export function* errorsWorker({ payload }: any) {
  const { errors } = payload

  if (errors && errors.status >= 400) {
    if (errors?.data?.errors?.length > 0) {
      yield all(
        errors.data.errors.map((error: string | { [key: string]: string }) => {
          if (typeof error !== 'string') {
            return
          }
          return put(
            showNotificationAction({
              message: error.replace('::', ':'),
              type: 'error'
            })
          )
        })
      )
    } else {
      yield put(
        showNotificationAction({
          message: errors.statusText,
          type: 'error'
        })
      )
    }
  }
}

export function* loginWorker({ payload }: any) {
  const { data } = payload

  yield put(setUserDataAction({ loading: true }))

  // @ts-ignore
  const { response, errors } = yield call(fetchDataWorker, {
    api: userAPI.login,
    type: types['USER'],
    params: { params: data }
  })

  if (response) {
    const { two_factor = null, token = null } = response?.data ?? {}

    const { required: is2FA = false } = two_factor || {}

    yield put(setUserDataAction({ is2FA, loaded: true }))

    if (token) {
      yield call(setTokenToLocalStorage, { payload: { token } })
    }

    if (!is2FA) {
      yield call(startSecuritySessionWorker)
      yield call(userInfoWorker)
    }
  }

  if (errors) {
    yield call(errorsWorker, { payload: { errors } })
  }

  yield put(setUserDataAction({ loading: false }))
}

export function* setTokenToLocalStorage({ payload }: any) {
  const { token } = payload
  if (token) {
    localStorage.setItem('access_token', token.access_token)
    localStorage.setItem('refresh_token', token.refresh_token)
  }
}

export function* login2FAWorker({ payload }: any) {
  const { data } = payload

  yield put(setUserDataAction({ loading: true }))

  // @ts-ignore
  const { response, errors } = yield call(fetchDataWorker, {
    api: userAPI.login2FA,
    type: types['USER'],
    params: { params: data }
  })

  const { token = null } = response?.data ?? {}

  if (token) {
    yield put(cancel2FAAction())

    yield call(setTokenToLocalStorage, { payload: { token } })

    yield call(startSecuritySessionWorker)

    yield call(userInfoWorker)
  }

  if (errors) {
    yield call(errorsWorker, { payload: { errors } })
  }

  yield put(setUserDataAction({ loading: false }))
}

export function* registerWorker({ payload }: any) {
  yield put(setUserDataAction({ loading: true }))

  // @ts-ignore
  const { response, errors } = yield call(fetchDataWorker, {
    api: userAPI.register,
    type: types['USER'],
    params: { params: payload }
  })

  if (response?.status === 'success') {
    yield put(setUserDataAction({ data: { registerEmail: payload.email } }))
  }

  if (errors) {
    yield call(errorsWorker, { payload: { errors } })
  }

  yield put(setUserDataAction({ loading: false }))
}

export function* recoveryWorker({ payload }: any) {
  yield put(setUserDataAction({ loading: true }))

  // @ts-ignore
  const { response, errors } = yield call(fetchDataWorker, {
    api: userAPI.recovery,
    type: types['USER'],
    params: { params: payload }
  })

  if (response?.status === 'success') {
    yield put(setUserDataAction({ data: { recoveryEmail: payload.email } }))
  }

  if (errors) {
    yield call(errorsWorker, { payload: { errors } })
  }

  yield put(setUserDataAction({ loading: false }))
}

export function* userInfoWorker() {
  const token = localStorage.getItem('access_token')

  if (!token) {
    return
  }

  yield put(setUserDataAction({ data: {}, loaded: false }))

  // @ts-ignore
  const { response, errors } = yield call(fetchDataWorker, {
    api: userAPI.getUserInfo,
    type: types['USER'],
    params: { token }
  })

  if (response?.status === 'success') {
    yield call(startSecuritySessionWorker)
    yield put(setUserDataAction({ data: response.data, loaded: true }))
  }
}

export function* startSecuritySessionWorker() {
  const token = localStorage.getItem('access_token')

  if (!token) {
    return
  }

  // @ts-ignore
  const { response } = yield call(fetchDataWorker, {
    api: userAPI.startSecuritySession,
    type: types['USER'],
    params: { token }
  })

  if (response?.status === 'success') {
  }
}
