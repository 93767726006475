import clsx from 'clsx'
import { FC } from 'react'

import Icon from '@/components/Icon'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './LogoIcon.module.scss'

interface LogoProps extends DefaultComponentProps {
  light?: boolean
}

const LogoIcon: FC<LogoProps> = ({ className = '', light = false }) => (
  <div
    className={clsx(styles['logo-icon'], className, {
      [styles['logo-icon_light']]: light
    })}
  >
    <Icon className={styles['logo-icon__icon']} name="getuniq/53" />
  </div>
)

export default LogoIcon
