import { types } from './types'

export const setCSRFToken = (token: string) => {
  return {
    type: types['UI/SET_CSRF_TOKEN'],
    payload: token
  }
}

export const setSettingsAction = (props: any) => {
  return {
    type: types['UI/SET_SETTINGS'],
    payload: props
  }
}
