import { types } from './types'

export const loginAction = (props: any) => {
  return {
    type: types['USER/LOGIN'],
    payload: props
  }
}

export const login2FAAction = (props: any) => {
  return {
    type: types['USER/LOGIN_2FA'],
    payload: props
  }
}

export const registerAction = (props: any) => {
  return {
    type: types['USER/REGISTER'],
    payload: props
  }
}

export const setUserDataAction = (props: any) => {
  return {
    type: types['USER/SET_DATA'],
    payload: props
  }
}

export const cancel2FAAction = () => {
  return {
    type: types['USER/CANCEL_2FA']
  }
}

export const recoveryAction = (props: any) => {
  return {
    type: types['USER/RECOVERY'],
    payload: props
  }
}

export const getUserInfoAction = () => {
  return {
    type: types['USER/INFO']
  }
}

export const startUserSecuritySessionAction = () => {
  return {
    type: types['USER/START_SECURITY_SESSION']
  }
}
