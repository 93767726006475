import { takeEvery } from 'redux-saga/effects'

import { NOTIFICATION_REMOVE_AFTER_DELAY, NOTIFICATION_SHOW } from '../types'

import { addNotification, removeNotificationAfterDelay } from './worker'

export function* watchNotifications() {
  // @ts-ignore
  yield takeEvery(NOTIFICATION_SHOW, addNotification)
  // @ts-ignore
  yield takeEvery(NOTIFICATION_REMOVE_AFTER_DELAY, removeNotificationAfterDelay)
}
