import { useDispatch, useSelector } from 'react-redux'

import {
  clearTextsAction,
  getTextBySlugAction,
  setTextsDataAction
} from '@/bus/texts/actions'
import { IRootState } from '@/store/reducer'

export const useTexts = () => {
  const dispatch = useDispatch()
  const {
    loading,
    loaded,
    errors,
    failed,
    interSection,
    data = {}
  } = useSelector(({ texts }: IRootState) => texts)

  const getTextsBySlug = (props: object) => dispatch(getTextBySlugAction(props))

  const clearTexts = () => dispatch(clearTextsAction())

  const setTextsData = (props: object) => dispatch(setTextsDataAction(props))

  return {
    data,
    getTextsBySlug,
    clearTexts,
    loading,
    loaded,
    errors,
    failed,
    setTextsData,
    interSection
  }
}
